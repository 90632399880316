<template>
  <div class="p-3">
    <b-row class="bg-tabs-custom">
      <b-col><span>Transaction Detail</span></b-col>
      <b-col class="text-right"
        ><span class="transaction-date"
          >Date/Time Transaction: {{ detail.created_time }}
        </span>
      </b-col>
    </b-row>
    <b-row class="bg-white content-section">
      <b-col>
        <b-row>
          <b-col class="title" md="5">Number POS/MIM:</b-col>
          <b-col>{{ detail.invoice_no }}</b-col>
        </b-row>
        <b-row>
          <b-col class="title" md="5">Name-Surname Buyer:</b-col>
          <b-col
            >{{ detail.customer_name }}
            <button
              @click.prevent="
                $router.push(`/report/customer/${detail.user_guid}`)
              "
              class="icon-to-point"
            >
              <font-awesome-icon icon="chevron-right" size="xs" /></button
          ></b-col>
        </b-row>
        <b-row
          ><b-col class="title" md="5">Branch:</b-col
          ><b-col>{{ detail.branch_name }}</b-col></b-row
        >
        <b-row>
          <b-col class="title" md="5"
            >Additional Notes
            <font-awesome-icon
              icon="pencil-alt"
              size="xs"
              class="pointer"
              @click.prevent="editNote"
          /></b-col>
          <b-col>{{ detail.note ? detail.note : "-" }}</b-col>
        </b-row>
      </b-col>

      <b-col md="3">
        <b-row
          ><b-col class="title">Reference Number:</b-col>
          <b-col>{{
            detail.transaction_ref_id ? detail.transaction_ref_id : "-"
          }}</b-col></b-row
        >
        <b-row>
          <b-col class="title">Net Price (Baht):</b-col>
          <b-col>{{ detail.grand_total | numeral("0,0.00") }}</b-col>
        </b-row>
        <b-row>
          <b-col class="title">Sales Channel:</b-col
          ><b-col> {{ detail.source_name }}</b-col>
        </b-row>
        <b-row>
          <b-col class="title">Tracking No :</b-col
          ><b-col> {{ detail.external_tracking_no || "-" }}</b-col>
        </b-row>
        <b-row>
          <b-col class="title">External Order ID :</b-col
          ><b-col> {{ detail.external_order_id || "-" }}</b-col>
        </b-row>
      </b-col>

      <b-col>
        <b-row>
          <b-col class="title" cols="5">Date/Time Spending:</b-col>
          <b-col>{{ detail.created_time }}</b-col>
        </b-row>
        <b-row>
          <b-col class="title" cols="5">Reward Point (Points):</b-col>
          <b-col
            >{{ detail.transaction_point | numeral("0,0") }} (Total Point:
            {{ detail.total_point | numeral("0,0") }})
          </b-col>
        </b-row>
        <b-row>
          <b-col class="title" cols="5">View Reward Points</b-col>
          <b-col>
            <div class="d-flex justify-content-between">
              <div class="mr-3 min-width-fix">Center Point</div>
              <button @click.prevent="modalCenterPoint" class="icon-to-point">
                <font-awesome-icon icon="chevron-right" size="xs" />
              </button>
            </div>
            <div class="mt-1 d-flex justify-content-between">
              <div class="mr-3 min-width-fix">Branch Point</div>
              <button @click.prevent="modalBranch" class="icon-to-point">
                <font-awesome-icon icon="chevron-right" size="xs" />
              </button>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="title" cols="5">Status: </b-col>
          <b-col
            ><span
              v-if="detail.status_id === 1 && detail.deleted === 0"
              class="consent-allow"
              >Succeed</span
            ><span
              class="status-void"
              v-if="detail.is_void === 1 && detail.status_id === 1"
            >
              (Refund)</span
            >
            <span
              v-if="
                (detail.status_id === 2 || detail.status_id === 3) &&
                detail.is_void === 1
              "
              class="status-void"
              >Refund</span
            >
            <span v-if="detail.deleted === 1" class="status-cancel"
              >Delete</span
            >
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    detail: {
      required: true,
      type: Object,
    },
  },
  methods: {
    editNote() {
      this.$emit("modalEditNote");
    },
    modalBranch() {
      this.$emit("modalBranch");
    },
    modalCenterPoint() {
      this.$emit("modalCenterPoint");
    },
  },
};
</script>

<style lang="scss" scoped>
.bg-tabs-custom {
  text-align: left;
  border: none;
  background: var(--secondary-color);
  border-radius: 0px;
  color: var(--font-color);
  font-weight: 600;
  padding: 10px 0;
}
.ft-black {
  color: #000;
}
.consent-allow {
  color: #06c306;
}
.status-cancel {
  color: red;
}
.status-void {
  color: var(--primary-color);
}
.title {
  color: #000;
  font-weight: 600;
}
.content-section {
  padding: 15px 0;
  line-height: 23px;
  color: gray;
}
.icon-to-point {
  background-color: var(--primary-color);
  color: #fff;
  border: none;
  border-radius: 3px;
}
.transaction-date {
  color: var(--primary-color);
}
.status-return-1 {
  color: #cd3000;
}
.min-width-fix {
  min-width: 150px;
}
</style>
